import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const setting = {
  dots: true,
  autoplay: true,
  autoplaySpeed: 7000,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: true,
  arrows: true,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        arrows: false
      }
    }
  ]
}


export default props => (
  <StaticQuery
    query = {graphql`
      query {
        allFile(
          filter: {
            extension: { regex: "/(jpg)|(png)|(jpeg)/" }
            relativeDirectory: { eq: "gallery" }
          }
        ) {
          edges {
            node {
              base
              childImageSharp{
                sizes(maxWidth: 1920) {
                  ...GatsbyImageSharpSizes_withWebp
                }
              }
            }
          }
        }
      }
    `}
    render = { data => (
      <Slider { ...setting }>
        {data.allFile.edges.slice(0, `${props.limit}`).map(image => (
          <Img key={image.node.childImageSharp.sizes.src} sizes={image.node.childImageSharp.sizes} style={{ margin: '3rem 0' }} />
        ))}
      </Slider>
    ) }
  />
)