import React from "react"
// import { Link } from "gatsby"
import NewsList from "../components/news-list"
import { Helmet } from "react-helmet"
import ArtList from "../components/art-slider"
import Video from "../components/video"
import LogoLastFarm from "../images/logo-lastfarm.svg"
import LogoTwitter from "../images/twitter.svg"
import LogoEmail from "../images/email.svg"
import Layout from "../components/layout"

export default (props) => {
  return (
    <Layout>
      <Helmet bodyAttributes={{ class: 'wit-home' }} />
      <Video
        videoSrcURL="https://www.youtube.com/embed/KvYRCO9Qfo4?rel=0;&controls=0;&autoplay=1;&mute=1"
        videoTitle="Intro video"
      />
      <div className="body make__grid">
        <div className="about-game">
          <div className="about-game__banner">
            <div className="about-game__logo"><img src={ LogoLastFarm } alt="The Last Farm" /></div>
            <div className="about-game__purchase">
              <div className="about-game__text">Available soon on Steam</div>
              <div className="about-game__actions">
                <a href="https://store.steampowered.com/app/1330610" className="btn primary btn--steam" target="_blank" rel="noopener noreferrer">Buy on Steam</a></div>
            </div>
          </div>
          <div className="about-game__body-text">
            <p>The Last Farm is a 2D pixel art farming simulator, set in a harsh world that is trying to recover from devastating wars and plagues. Following an attack on the town of Riverside that left their farm in ruins, the player is tasked with rebuilding the farm and saving the town from starvation. Selective breeding of crops for genetic traits such as disease resistance will help players survive the virus-carrying blightstorms, while using recovered technology to help automate their farm will allow them to feed the ever-increasing population of the community they have joined.</p>
            <p>Players will be tasked with balancing their own needs versus those of the town of Riverside. Will you give your first harvest over to the town to replenish their ever-shrinking food stores, or will you sell it to fund the expansion of your farm? Will you try and breed your crops to resist the diseases carried by the blightstorms, or to give greater yields per plant? And, as a member of the town council, you will take part in votes that shape the future of the town. Decide whether to increase resources to security or trade, allow refugees into the town or turn them away, and many other decisions that will determine how Riverside evolves.</p>
          </div>
          <div className="art_list__slider">
            <ArtList limit="10" />
          </div>
        </div>
        <hr />
        <div className="news">
          <h2>Latest News</h2>
          <div className="news__list">
            <NewsList limit="3" />
          </div>
          {/* <div className="news__actions">
            <Link to="/news" className="btn primary">Read More News</Link>
          </div> */}
        </div>
        <hr />
        <div className="about-wit">
          <h2>About Works In Theory</h2>
          <p>I'm Gary Chambers, life-long indie, giant nerd, and founder of Works In Theory.</p>
          <p>After joining Introversion Software straight out of University, I've spent the last 15 years as an indie developer, the highlight of which was working on the BAFTA-award winning Prison Architect. Through ups and downs, and many games shipped on multiple platforms, the opportunity presented itself to work on my own games, and I decided to go for it. And so, on an otherwise unremarkable day in mid 2019, Works In Theory was born! </p>
        </div>
        <hr />
        <div className="contact-wit">
          <button className="skipto-contact" name="contact">skip to contact</button>
          <h2>Contact <br />Works In Theory</h2>
          <div className="contact-wit__text">
            <p>If you have any questions, comments, or otherwise need <br />to get in touch you can do so via:</p>
            <div className="contact-methods">
                <a className="contact-method" href="mailto:contact@worksintheory.co.uk" alt="Email me">
                  <img src={ LogoEmail } alt="Email Works In Theiry" />
                  <div className="label">Email Works In Theory</div>
                </a>
                <a className="contact-method" href="https://twitter.com/GamesInTheory" alt="@GamesInTheory" target="_blank" rel="noreferrer noopener">
                  <img src={ LogoTwitter } alt="@GamesInTheory" />
                  <div className="label">@GamesInTheory</div>
                </a>
            </div>
          </div>
        </div>
      </div>
  </Layout>
  )
}
